<template>
  <div class='border-t bg-white flex flex-col py-12'>
    <div class='layout-container w-full bg-white flex flex-col lg:flex-row gap-y-8 justify-between px-4 lg:px-0 text-xs lg:text-sm text-gray-700'>
      <div>
        <div class='font-semibold text-sm lg:text-base text-gray-900'>Korean Society of Otorhinolaryngology-Head and Neck Surgery</div>
        <div class='mt-2 text-sm'>
          <span class='uppercase inline-block opacity-70 w-36 '>Contact Number</span>
          +82-70-7730-9105
        </div>
        <div class='mt-2 text-sm'>
          <span class='uppercase inline-block opacity-70 w-36 '>Email</span> 
          info@korl.or.kr
        </div>
        <div class='mt-2 text-sm'>
          <span class='uppercase inline-block opacity-70 w-36 '>Homepage</span> 
          <a href='https://www.korl.or.kr' target='_blank' class='underline'>https://www.korl.or.kr</a>
        </div>
      </div>
      <div>
        <div class='font-semibold text-sm lg:text-base text-gray-900'>ICORL 2022 Congress Secretariat / KEION LTD</div>
        <div class='mt-2 text-sm'>
          <span class='uppercase inline-block opacity-70 w-36 '>Contact Number</span>
          +82-2-6956-0620
        </div>
        <div class='mt-2 text-sm'>
          <span class='uppercase inline-block opacity-70 w-36 '>Email</span> 
          secretariat@icorl.org
        </div>
        <div class='mt-2 text-sm'>
          <span class='uppercase inline-block opacity-70 w-36 '>Homepage</span> 
          <a href='https://www.koreaorlmeeting.org/' target='_blank' class='underline'>https://www.koreaorlmeeting.org/</a>
        </div>
      </div>
      <div>
        <img :src='eventConfigLogoImageUrl'
             class='h-16 lg:h-20 cursor-pointer mb-6'
             @click='goToHome'/>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'Icorl2022LayoutFooter',
  computed: {
    ...mapGetters('events', [
      'eventConfigLogoImageUrl',
    ]),
  },
  methods: {
    goToHome () {
      this.$router.push({name: 'Home'}).catch(() => {})
    },
  }
}
</script>
